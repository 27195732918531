import {useState, useRef, useEffect} from 'react';
import {VoiceDataType} from '../../../data/voicesData.types';

export const useVoiceSelector = () => {
	const [playing, setPlaying] = useState<VoiceDataType | null>(null);
	const audioRef = useRef(new Audio());

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current.onended = () => {};
		}
		if (playing) {
			try {
				audioRef.current = new Audio(playing.src);
				audioRef.current.play();
				audioRef.current.addEventListener('ended', () => {
					setPlaying(null);
				});
			} catch (error) {
				console.error('Error playing audio:', error);
				setPlaying(null);
			}
		}
	}, [playing]);

	return {
		playing,
		setPlaying,
		audioRef,
	};
};
