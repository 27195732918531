import './PaywallModal.css';
import {useEffect, useState} from 'react';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {PaywallProps} from './types';
import {PaywallModalContainer} from './PWContainer';
import {PWBtn} from './PWBtn';
import {PWList} from './PWList';
import {UltraPaywall} from './UltraPaywall/UltraPaywall';
import {PWPaymentPlan} from './PWPaymentPlan';
import {webEventsApi} from '../../amplitude/webEvents';
import paywallBg from './images/image.webp';
import {PeriodSwitcher} from './PeriodSwitcher/PeriodSwitcher';
import {ProductPrice, ProductsV2} from '../../api/usersApi/usersApi.types';
import appleStarsImg from './images/appleStars.svg';
import googleStarsImg from './images/googleStars.svg';
import gplayImg from './images/play.png';
import appleImg from './images/apple.svg';
import {isAndroid} from '../../common-lib/src/utils';

export const PaywallModal = (props: PaywallProps) => {
	const {products, loadingProducts} = props;

	const noProducts = !products?.premium && !products?.ultra;
	if (loadingProducts || noProducts) {
		if (props.openned && noProducts) {
			console.warn('No products found');
		}
		return null;
	}

	if (props.type === 'ultra') {
		const ultraProduct = products?.ultra;
		if (ultraProduct) {
			return <UltraPaywall {...props} />;
		}
	}

	if (!products?.premium && products?.ultra) {
		return <UltraPaywall {...props} />;
	}

	return <DefaultPaywallModal {...props} />;
};

export const DefaultPaywallModal = ({
	openned,
	onClose,
	onSuccess,
	loading,
	products,
	loadingProducts,
}: PaywallProps) => {
	const [selectedPrice, setSelectedPrice] = useState<ProductPrice | null>(null);
	const [selectedProductName, setSelectedProductName] =
		useState<keyof ProductsV2>('premium');

	useEffect(() => {
		if (products) {
			if (products.ultra) {
				setSelectedProductName('ultra');
			} else {
				setSelectedProductName('premium');
			}
		} else {
			console.warn('No products found');
		}
	}, [products]);

	const selectedProduct = products?.[selectedProductName];
	const isUltraSelected = selectedProductName === 'ultra';

	const handleSelectPrice = (price: ProductPrice) => {
		setSelectedPrice(price);
		webEventsApi.paywallProductSelected({
			product_id: price.id,
			product_name: selectedProductName,
			interval: price.interval,
		});
	};

	const handleSelectProduct = (isUltra: boolean) => {
		setSelectedProductName(isUltra ? 'ultra' : 'premium');
	};

	useEffect(() => {
		const product = products?.[selectedProductName];
		if (!product) {
			return;
		}
		const mostDailyExpensiveCheap = product.prices.reduce((prev, curr) => {
			return prev.dailyAmount < curr.dailyAmount ? prev : curr;
		});
		setSelectedPrice(mostDailyExpensiveCheap);
	}, [selectedProductName]);

	const appStoreRates = '11 561';
	const googlePlayRates = '16 931';

	const isAppStore = !isAndroid;

	return (
		<PaywallModalContainer openned={openned} onClose={onClose}>
			<img src={paywallBg} alt="background" className="paywall-modal__bg" />
			<div className="paywall-modal__header">
				<h2 className="paywall-modal__header-h2">Unlock all features</h2>
			</div>
			<PeriodSwitcher
				onChange={handleSelectProduct}
				isUltra={isUltraSelected}
			/>
			<PWList
				items={[
					{img: '🖼️', text: 'Photos & Voices'},
					{img: '💫', text: 'Unlimited messages'},
					{img: '💖', text: 'Role-playing', forUlta: true},
				]}
				isUltraSelected={isUltraSelected}
			/>
			<div className="paywall-modal__stars-container">
				<img src={isAppStore ? appleImg : gplayImg} alt="apple" className="paywall-modal__store-img" />
				<div className="paywall-modal__stars-container-stars">
					<p className="paywall-modal__stars-container-stars-title">{isAppStore ? 'App Store' : 'Google Play'}</p>
					<p className="paywall-modal__stars-container-stars-reviews">{isAppStore ? appStoreRates : googlePlayRates} reviews</p>
				</div>
				<img src={isAppStore ? appleStarsImg : googleStarsImg} alt="stars" className="paywall-modal__stars-img" />
			</div>
			{loadingProducts ? (
				<Spinner withLayout={false} />
			) : (
				<div className="paywall-modal__prices">
					{selectedProduct?.prices.map((price, i) => {
						return (
							<PWPaymentPlan
								key={price.id}
								price={price}
								isSelected={selectedPrice?.id === price.id}
								priceBottom=""
								onSelect={handleSelectPrice}
								discount={price.discountPercentage}
							/>
						);
					})}
				</div>
			)}
			<p className='paywall-modal__renewal-text'>Auto-renews, cancel at any time</p>
			<PWBtn
				loading={loading}
				selectedPrice={selectedPrice}
				onSuccess={onSuccess}
				selectedPlan={selectedProductName}
				text="Get access"
			/>
		</PaywallModalContainer>
	);
};
