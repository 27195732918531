import {VoiceFullData} from '../../api/types';
import {VoiceDataType} from '../../common-lib/src/data/voicesData.types';
import {Pronoun} from './NamePronoun/NamePronoun';

export const convertPronoun = (pronoun: string): Pronoun => {
	switch (pronoun) {
		case 'he/him':
			return 'He';
		case 'she/her':
			return 'She';
		case 'they/them':
			return 'They';
		default:
			return 'N/A';
	}
};

const convertVoiceFullDataToVoiceDataType = (
	voice: VoiceFullData
): VoiceDataType | null => {
	if (!voice.attributes.preview.data) {
		return null;
	}

	return {
		src: voice.attributes.preview.data.attributes.url,
		name: voice.attributes.name,
		isFemale: voice.attributes.category === 'female',
	};
};

export const convertVoicesFullDataToVoiceDataType = (
	voices: VoiceFullData[]
): VoiceDataType[] => {
	return voices
		.map(convertVoiceFullDataToVoiceDataType)
		.filter(
			(voice): voice is VoiceDataType => voice !== null
		) as VoiceDataType[];
};
