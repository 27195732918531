import {api} from './common-lib/src/api/Api';

export const isDevb2c = () => {
	const prodHosts = ['botify.ai', 'botif.ai'];
	return prodHosts.every((host) => !window.location.host.includes(host));
};

const defaultDescription =
	'Botify AI is a whole universe of AI characters. Here you can chat with your favorite anime characters, celebrities, heroes from books and movies, historical figures';

export const resetTitleAndDescription = () => {
	document.title = 'Botify AI';

	document
		.querySelector('meta[name="description"]')
		?.setAttribute('content', defaultDescription);
};

export const updateCanonicalUrl = () => {
	const link = document.querySelector('link[rel="canonical"]');

	const url = window.location.href.split('?')[0];
	if (link) {
		link.setAttribute('href', url);
	} else {
		const canonical = document.createElement('link');
		canonical.rel = 'canonical';
		canonical.setAttribute('href', url);
		document.head.appendChild(canonical);
	}
};

export const updateHreflang = () => {
	const links = document.querySelectorAll('link[rel="alternate"]');

	const url = window.location.href;

	if (links.length === 0) {
		const link = document.createElement('link');
		link.rel = 'alternate';
		link.hreflang = 'us';
		link.setAttribute('href', `${url}`);
		document.head.appendChild(link);

		const linkDefault = document.createElement('link');
		linkDefault.rel = 'alternate';
		linkDefault.hreflang = 'x-default';
		linkDefault.setAttribute('href', `${url}`);
		document.head.appendChild(linkDefault);
	} else {
		links.forEach((link) => {
			link.setAttribute('href', `${url}`);
		});
	}
};

export const createJsonLd = (type: string, description: string) => {
	return {
		'@context': 'http://schema.org',
		'@type': type,
		name: 'Botify AI',
		alternateName: 'Botify AI',
		description: description,
		url: 'https://botify.ai',
		image: 'https://botify.ai/Union.svg',
	};
};

export const addJsonLd = (content: object) => {
	const script = document.createElement('script');
	script.type = 'application/ld+json';
	script.innerHTML = JSON.stringify(content);
	document.head.appendChild(script);
};

export const removeJsonLds = () => {
	const scripts = document.querySelectorAll(
		'script[type="application/ld+json"]'
	);
	scripts.forEach((script) => {
		script.remove();
	});
};

export const createWebPageJsonLd = (description = defaultDescription) => {
	const meta = {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		name: 'Botify AI',
		url: 'https://www.botify.ai',
		mainEntityOfPage: 'https://www.botify.ai',
		headline: 'Botify AI',
		description: description,
		image: 'https://botify.ai/Union.svg',
	};

	addJsonLd(meta);
};

export const createWebSiteJsonLd = (description = defaultDescription) => {
	const meta = {
		'@context': 'http://schema.org',
		'@type': 'Website',
		name: 'Botify AI',
		alternateName: 'Botify AI',
		description: description,
		url: 'https://botify.ai',
		image: 'https://botify.ai/Union.svg',
	};

	addJsonLd(meta);
};

export const createBreadcrumbListJsonLd = (name: string) => {
	const meta = {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: [
			{
				'@type': 'ListItem',
				position: 1,
				name: 'Botify AI',
				item: 'https://botify.ai/',
				url: 'https://botify.ai/',
			},
			{
				'@type': 'ListItem',
				position: 2,
				name: name,
				item: window.location.href,
				url: window.location.href,
			},
		],
	};

	addJsonLd(meta);
};

export const createItemListJsonLd = (items: {name: string; url: string}[]) => {
	const meta = {
		'@context': 'http://schema.org',
		'@type': 'ItemList',
		itemListElement: items.map((item, index) => ({
			'@type': 'ListItem',
			position: index + 1,
			name: item.name,
			url: item.url,
		})),
	};

	addJsonLd(meta);
};

export const createBotPageJsonLd = (name: string) => {
	removeJsonLds();

	createWebSiteJsonLd();
	createBreadcrumbListJsonLd(name);
};

export const createCategoryPageJsonLd = (
	name: string,
	list: {name: string; url: string}[]
) => {
	removeJsonLds();

	createWebPageJsonLd();
	createWebSiteJsonLd();
	createBreadcrumbListJsonLd(name);
	createItemListJsonLd(list);
};

const utmKeys = [
	'utm_source',
	'utm_medium',
	'utm_content',
	'utm_term',
	'adname',
	'campaign_id',
	'ad_id',
	'adgroup_id',
	'ad_position',
	'source',
	'device',
	'region',
	'region_interest',
];
const UTM_PARAMS_COOKIE_NAME = '_params';
export const saveUtmParams = () => {
	const utms = new URLSearchParams(window.location.search);
	const params: Record<string, string | null | undefined> = {};
	utmKeys.forEach((key) => {
		params[key] = utms.get(key);
	});
	Object.keys(params).forEach((key) => {
		if (params[key] === null || params[key] === undefined) {
			delete params[key];
		}
	});
	api.setCookie(UTM_PARAMS_COOKIE_NAME, JSON.stringify(params));
};

export const getUtmParamsCookie = () => {
	const params = api.getCookie(UTM_PARAMS_COOKIE_NAME);
	try {
		if (params) {
			const data = JSON.parse(params);
			if (data && Object.keys(data).length > 0) {
				return data;
			} else {
				return null;
			}
		}
		return null;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const clearUtmParamsCookie = () => {
	api.setCookie(UTM_PARAMS_COOKIE_NAME, '');
};
