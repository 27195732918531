import logo from '../../../../images/header-logo.svg';
import {ChatMainWithLazyLoad} from '../../../../common-lib/src/components/ChatCommon/ChatMain/ChatMain';
import {FeedbackModal} from '../../../Feedback/Feedback';
import {SharingModal} from './SharingModal/SharingModal';
import './BotPage.css';
import styles from './DesktopBotPage.module.css';
import {SideBar} from './Sidebar/SideBar';
import {BotPageProps} from './BotPage.types';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {LimitInfoText} from './LimitInfo/LimitInfoText';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {useContext} from 'react';
import {AppContext} from '../../../../App';
import {SendMeBtn} from './SendMeBtn/SendMeBtn';
import {LlmSelector} from './LlmSelector/LlmSelector';
import {
	backImg,
	otherImg,
	reportImg,
	editImg,
	shareImg,
	sendImg,
	sendActiveImg,
	clearImg,
	deleteImg,
	profileImg,
	muteImg,
	unmuteImg,
	deleteRedImg,
} from './images';
import {Spinner} from '../../../../common-lib/src/components/Spinner/Spinner';
import {useConfirmation} from '../../../../common-lib/src/hooks/useConfirmation/useConfirmation';
import {ContinueBtn} from './ContinueBtn/ContinueBtn';

export const MuteBtn = ({
	isMuted,
	handleSetIsMuted,
}: {
	isMuted: boolean;
	handleSetIsMuted: (isMuted: boolean) => void;
}) => {
	return (
		<div className="action" onClick={() => handleSetIsMuted(!isMuted)}>
			<img src={isMuted ? unmuteImg : muteImg} alt="mute" />
		</div>
	);
};

export const DesktopBotPage = (props: BotPageProps) => {
	const {
		bot,
		handleShareClick,
		handleOpen,
		isOtherActionsOpened,
		handleClearChat,
		handleDeleteBot,
		handleDeleteChat,
		setIsFeedbackOpened,
		chatRef,
		isTyping,
		context,
		inputRef,
		inputHeight,
		inputValue,
		handleInputChange,
		handleKeyDown,
		handleSendMessage,
		isSharingOpened,
		setIsSharingOpened,
		isFeedbackOpened,
		handleSubmitReport,
		handleFocus,
		handleBlur,
		actions,
		userActions,
		isChatLimited,
		limitTimeLeft,
		handleEditBot,
		handleGoProfile,
		availableLlms,
		selectedLlm,
		handleSelectLlm,
		isMuted,
		handleSetIsMuted,
		isGlobalLoading,
		pagination,
		handleContinue,
		isLoadingAudio,
	} = props;
	const navigate = useNavigate();
	const {handleLoginOrPaywall} = useContext(AppContext);

	const handleGoBack = () => {
		navigate(`/`);
	};

	const attributes = bot?.attributes || {};

	const {open, ConfirmationDialog} = useConfirmation({
		title: 'Delete Bot',
		text: 'Are you sure you want to delete this bot?',
		okButtonText: 'Delete',
		onSubmit: handleDeleteBot,
	});

	const {
		open: openDeleteChat,
		ConfirmationDialog: ConfirmationDialogDeleteChat,
	} = useConfirmation({
		title: 'Delete Chat',
		text: 'Are you sure you want to delete this chat? All messages will be deleted.',
		okButtonText: 'Delete',
		onSubmit: handleDeleteChat,
	});

	const {open: openClearChat, ConfirmationDialog: ConfirmationDialogClearChat} =
		useConfirmation({
			title: 'Clear Chat',
			text: 'Are you sure you want to clear this chat? All messages will be deleted.',
			okButtonText: 'Clear',
			onSubmit: handleClearChat,
		});

	return (
		<div className="mobile-bot-page">
			{isGlobalLoading && <Spinner />}
			<ConfirmationDialog />
			<ConfirmationDialogDeleteChat />
			<ConfirmationDialogClearChat />
			<div className={cn('header', styles.header)}>
				<div className={styles.logoContainer}>
					<Link to="/" title="Home page">
						<img src={logo} alt="logo" className="logo" />
					</Link>
				</div>
				<div className={styles.headerContainer}>
					<img
						src={backImg}
						alt="back"
						className="back"
						onClick={handleGoBack}
					/>
					<div className="profile">
						<div className="texts">
							<h1 className="name" onClick={handleGoProfile}>
								{attributes.name}
							</h1>
						</div>
					</div>
					<div className="actions">
						<LlmSelector
							llms={availableLlms}
							selectedLlm={selectedLlm}
							handleSelectLlm={handleSelectLlm}
						/>
						<MuteBtn isMuted={isMuted} handleSetIsMuted={handleSetIsMuted} />
						<div className="action" onClick={handleShareClick}>
							<img src={shareImg} alt="share" />
						</div>
						<div className="action" id="bot-actions" onClick={handleOpen}>
							<img src={otherImg} alt="share" />
							{isOtherActionsOpened && (
								<div className="actions__box">
									{attributes.isOwner && (
										<div className="action__row" onClick={handleEditBot}>
											<img src={editImg} alt="edit bot" />
											<p>Edit Bot</p>
										</div>
									)}
									<div className="action__row" onClick={handleGoProfile}>
										<img src={profileImg} alt="profile page" />
										<p>Bot profile</p>
									</div>
									<div className="action__row" onClick={openClearChat}>
										<img src={clearImg} alt="clear chat" />
										<p>Clear Chat</p>
									</div>
									<div className="action__row" onClick={openDeleteChat}>
										<img src={deleteImg} alt="delete chat" />
										<p className="action__row_text">Delete Chat</p>
									</div>
									{attributes.isOwner && (
										<div className="action__row" onClick={open}>
											<img src={deleteRedImg} alt="delete bot" />
											<p className="action__row_text-red">Delete Bot</p>
										</div>
									)}
									<div
										className="action__row"
										onClick={() => setIsFeedbackOpened(true)}
									>
										<img src={reportImg} alt="report bot" />
										<p className="action__row_text-red">Report Bot</p>
									</div>
								</div>
							)}
						</div>
					</div>
					<img
						src={attributes.avatarUrl}
						alt={`${attributes.name} - 44 - ${attributes.createdAt}`}
						className={styles.avatar}
						title={attributes.name}
						onClick={handleGoProfile}
					/>
				</div>
			</div>
			<div className={styles.container}>
				<SideBar hasDiscoverBotsBtn />
				<div className={styles.chat}>
					<div className="content" style={{height: `100%`}} ref={chatRef}>
						<ChatMainWithLazyLoad
							pagination={pagination}
							isLoading={isTyping}
							messages={context}
							actions={actions}
							userActions={userActions}
						/>
					</div>
					<div
						className={cn(
							'input-field',
							styles.input,
							isChatLimited && styles.limited
						)}
					>
						<textarea
							ref={inputRef}
							name="input"
							id="input"
							className={'input'}
							style={{height: `${inputHeight}px`}}
							onChange={handleInputChange}
							value={inputValue}
							placeholder={
								isChatLimited
									? 'You have reached messages limit'
									: 'Type a message'
							}
							onKeyDown={handleKeyDown}
							onFocus={handleFocus}
							onBlur={handleBlur}
							disabled={isChatLimited}
						/>
						{isChatLimited && <LimitInfoText data={limitTimeLeft} />}
						{isChatLimited ? (
							<InButton
								id="continue"
								isFilled
								className={styles.continueLimitBtn}
								onClick={handleLoginOrPaywall}
							>
								Continue right now
							</InButton>
						) : (
							<>
								{!inputValue.trim().length &&
									!actions?.shouldShowVoteNotification &&
									bot.attributes.generatePhotos && (
										<SendMeBtn
											onClick={(text: string) =>
												handleSendMessage(`Send me ${text}`)
											}
											hasVideo={bot.attributes.generateVideos}
											disabled={isLoadingAudio || isTyping}
										/>
									)}
								{inputValue.trim() ? (
									<img
										src={inputValue.trim() ? sendActiveImg : sendImg}
										alt="send"
										className="send"
										onClick={() => handleSendMessage(inputValue)}
									/>
								) : (
									<ContinueBtn
										onClick={handleContinue}
										disabled={isTyping || isLoadingAudio}
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			<SharingModal
				isOpened={isSharingOpened}
				onClose={() => setIsSharingOpened(false)}
				link={window.location.href}
			/>
			<FeedbackModal
				isOpen={isFeedbackOpened}
				onClose={() => setIsFeedbackOpened(false)}
				onSubmit={handleSubmitReport}
			/>
		</div>
	);
};
