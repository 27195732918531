import {useContext, useEffect, useState} from 'react';
import {UserPronoun} from '../../../../api/usersApi/usersApi.types';
import {Pronoun} from '../../../CreateBot/NamePronoun/NamePronoun';
import {ChatPersonaProps} from './ChatPersonaModal';
import {AppContext} from '../../../../App';

const convertUserPronounToPronoun = (pronoun: UserPronoun): Pronoun => {
	if (!pronoun) {
		return 'N/A';
	}
	const mapper = {
		'he/him': 'He',
		'she/her': 'She',
		'they/them': 'They',
	};
	return (mapper[pronoun] || 'N/A') as Pronoun;
};

export const useChatPersona = ({
	onSubmit,
	onClose,
	data,
	showPairTooltip: showPairFromParent,
}: ChatPersonaProps) => {
	const {user} = useContext(AppContext);
	const [name, setName] = useState(data.personaName || '');
	const [pronoun, setPronoun] = useState<UserPronoun>(
		data.personaPronouns || null
	);
	const [personality, setPersonality] = useState(data.personaDescription || '');
	const [isAIPopupOpen, setIsAIPopupOpen] = useState(false);
	const [image, setImage] = useState<string | null>(
		user?.personaAvatar ?? null
	);
	const [file, setFile] = useState<File | null>(null);
	const [showPairTooltip, setShowPairTooltip] = useState(showPairFromParent);
	const handleImageChange = (file: File | null, value: string | null) => {
		setImage(value);
		setFile(file);
	};
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (showPairFromParent) {
			setShowPairTooltip(true);
		}
	}, [showPairFromParent]);

	useEffect(() => {
		const turnOffTooltip = () => {
			setShowPairTooltip(false);
		};
		if (showPairTooltip) {
			document.addEventListener('click', turnOffTooltip);
		}
		return () => document.removeEventListener('click', turnOffTooltip);
	}, [showPairTooltip]);

	useEffect(() => {
		if (isAIPopupOpen) {
			setImage(user?.personaAvatar ?? null);
		}
	}, [isAIPopupOpen]);

	const handlePronounChange = (value: Pronoun) => {
		const mapper: Record<Pronoun, UserPronoun> = {
			He: 'he/him',
			She: 'she/her',
			They: 'they/them',
			'N/A': null,
		};
		const pronoun = mapper[value] || null;
		setPronoun(pronoun);
	};

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			await onSubmit({name, pronoun, personality, file});
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSetName = (newname: string) => {
		newname = newname.replace(/[^\p{L}0-9\-\s]/gu, '');
		setName(newname);
	};

	return {
		name,
		pronoun,
		personality,
		handlePronounChange,
		handleSubmit,
		setName: handleSetName,
		setPronoun,
		setPersonality,
		convertUserPronounToPronoun,
		disabled:
			name.length < 3 || (personality.length < 3 && personality.length > 0),
		isAIPopupOpen,
		setIsAIPopupOpen,
		handleImageChange,
		image,
		file,
		showPairTooltip,
		isLoading,
	};
};
