import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {PWBtnProps} from './types';
import cn from 'classnames';

export const PWBtn = ({
	loading,
	selectedPrice,
	onSuccess,
	text,
	selectedPlan,
}: PWBtnProps) => {
	return (
		<InButton
			isFilled
			id="continue-paywall-modal"
			onClick={
				loading || !selectedPrice
					? () => {}
					: () => onSuccess(selectedPrice, selectedPlan)
			}
			className={cn('paywall-modal__btn', {
				'paywall-modal__btn-loading': loading,
			})}
		>
			{loading ? <>Loading</> : <>{text}</>}
		</InButton>
	);
};
