import {ChatResponse} from './api/Api';

export const removeC = () => {
	const cookies = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

export const convertToId = (str: string) =>
	str.replaceAll(' ', '-').toLowerCase();

const convertBase64ToType = (
	source: string | undefined | null,
	type: 'image' | 'video'
) => {
	if (!source) {
		return null;
	}
	const byteString = atob(source);
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const int8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteString.length; i++) {
		int8Array[i] = byteString.charCodeAt(i);
	}
	const blob = new Blob([int8Array], {
		type: type === 'image' ? 'image/png' : 'video/mp4',
	});
	return URL.createObjectURL(blob);
};

export const convertBase64ToVideo = ({base64_video}: ChatResponse) => {
	return convertBase64ToType(base64_video, 'video');
};

interface ConvertBase64ToImage {
	base64_image?: string | undefined | null;
}

export const convertBase64ToImage = ({base64_image}: ConvertBase64ToImage) => {
	return convertBase64ToType(base64_image, 'image');
};

export const isAndroid =
	navigator.userAgent.toLowerCase().indexOf('android') > -1;

//@ts-ignore
const isNotMSStream = !window.MSStream;
export const isIOS =
	/iPad|iPhone|iPod/.test(navigator.userAgent) && isNotMSStream;
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
