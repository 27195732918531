import {CategoryPreview} from '../api/types';
import {webApi} from '../api/webApi';

export const PREDEFINED_TAGS: CategoryPreview[] = webApi.convertTagsToPreview([
	{
		id: 36,
		attributes: {
			name: '💡 Helper',
			slug: 'helper',
			createdAt: '2024-05-23T15:16:22.731Z',
			updatedAt: '2024-05-23T15:16:23.877Z',
			publishedAt: '2024-05-23T15:16:23.872Z',
			sort: 6,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 4,
		attributes: {
			name: '🧚 Fairytale',
			slug: 'fairytale',
			createdAt: '2024-04-04T12:28:38.981Z',
			updatedAt: '2024-05-02T17:08:44.398Z',
			publishedAt: '2024-04-04T12:28:40.318Z',
			sort: 31,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 10,
		attributes: {
			name: '👽  Fictional',
			slug: 'fictional',
			createdAt: '2024-04-04T12:40:48.122Z',
			updatedAt: '2024-05-02T17:08:51.155Z',
			publishedAt: '2024-04-04T12:40:49.218Z',
			sort: 8,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 17,
		attributes: {
			name: '🦁  Furry',
			slug: 'furry',
			createdAt: '2024-04-04T12:44:12.825Z',
			updatedAt: '2024-05-02T17:08:59.209Z',
			publishedAt: '2024-04-04T12:44:13.844Z',
			sort: 15,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 9,
		attributes: {
			name: '🎮  Game',
			slug: 'game',
			createdAt: '2024-04-04T12:39:34.165Z',
			updatedAt: '2024-05-02T17:09:02.670Z',
			publishedAt: '2024-04-04T12:39:35.282Z',
			sort: 6,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 14,
		attributes: {
			name: '🏺  Historical',
			slug: 'historical',
			createdAt: '2024-04-04T12:42:46.359Z',
			updatedAt: '2024-05-02T17:09:19.259Z',
			publishedAt: '2024-04-04T12:42:47.393Z',
			sort: 12,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 19,
		attributes: {
			name: '✨  Magical',
			slug: 'magical',
			createdAt: '2024-04-04T12:45:51.885Z',
			updatedAt: '2024-05-02T17:09:27.928Z',
			publishedAt: '2024-04-04T12:45:52.942Z',
			sort: 17,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 21,
		attributes: {
			name: '👹  Monster',
			slug: 'monster',
			createdAt: '2024-04-04T12:47:18.879Z',
			updatedAt: '2024-05-02T17:09:34.436Z',
			publishedAt: '2024-04-04T12:47:20.118Z',
			sort: 19,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 13,
		attributes: {
			name: '🍿  Movies & TV',
			slug: 'movies',
			createdAt: '2024-04-04T12:42:08.884Z',
			updatedAt: '2024-05-02T17:09:37.932Z',
			publishedAt: '2024-04-04T12:42:15.813Z',
			sort: 11,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 37,
		attributes: {
			name: '🌟 Editorials',
			slug: 'editorials',
			createdAt: '2024-05-28T12:03:37.317Z',
			updatedAt: '2024-05-31T11:06:48.032Z',
			publishedAt: '2024-05-28T12:03:38.468Z',
			sort: 0,
			showOnExplore: true,
			userCanApply: false,
		},
	},
	{
		id: 25,
		attributes: {
			name: '🧛  Vampire',
			slug: 'vampire',
			createdAt: '2024-04-04T12:49:07.474Z',
			updatedAt: '2024-05-02T17:10:07.459Z',
			publishedAt: '2024-04-04T12:49:08.452Z',
			sort: 23,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 28,
		attributes: {
			name: '😇  VTuber',
			slug: 'vtuber',
			createdAt: '2024-04-04T12:51:10.848Z',
			updatedAt: '2024-05-02T17:10:13.544Z',
			publishedAt: '2024-04-04T12:51:11.964Z',
			sort: 26,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 12,
		attributes: {
			name: '🔫  Action',
			slug: 'action',
			createdAt: '2024-04-04T12:41:44.476Z',
			updatedAt: '2024-05-02T17:08:02.779Z',
			publishedAt: '2024-04-04T12:41:45.615Z',
			sort: 10,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 1,
		attributes: {
			name: '😽  Anime',
			slug: 'anime',
			createdAt: '2024-02-20T17:25:35.372Z',
			updatedAt: '2024-05-02T17:08:18.950Z',
			publishedAt: '2024-02-20T17:25:36.643Z',
			sort: 5,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 2,
		attributes: {
			name: '📚  Books',
			slug: 'books',
			createdAt: '2024-02-20T17:25:53.635Z',
			updatedAt: '2024-05-02T17:08:23.276Z',
			publishedAt: '2024-02-20T17:25:54.726Z',
			sort: 30,
			showOnExplore: true,
			userCanApply: true,
		},
	},
	{
		id: 3,
		attributes: {
			name: '👑  Celebrity',
			slug: 'celebrity',
			createdAt: '2024-04-04T12:28:20.465Z',
			updatedAt: '2024-05-02T17:08:26.443Z',
			publishedAt: '2024-04-04T12:28:22.237Z',
			sort: 7,
			showOnExplore: true,
			userCanApply: true,
		},
	},
]);
