import {HeaderContainer} from '../../Header/Header';
import styles from './ProfileHeader.module.css';
import cn from 'classnames';
import backImg from '../../../images/back-desk.svg';
import {
	ActionOptionsBtn,
	ActionOptionsRow,
	ActionOptionsToggleRow,
} from '../../ActionOptionsBtn/ActionOptionsBtn';
import editIcon from './images/edit.svg';
import shareIcon from './images/share.svg';
import reportIcon from './images/report.svg';
import eyeIcon from './images/eye.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppContext} from '../../../App';
import {useContext, useEffect, useState} from 'react';
import {logoutImg} from '../../ProfileOptionsList/images';
import {ChatPersonaDialog} from '../EditProfilePage/ChatPersona/ChatPersonaDialog';
import {usersApi} from '../../../api/usersApi/usersApi';
import {
	UserInfo,
	UserPronoun,
	UserPublicInfo,
} from '../../../api/usersApi/usersApi.types';
import {ChatPersonaModal} from '../EditProfilePage/ChatPersona/ChatPersonaModal';
import {useSnackbar} from 'notistack';
import {Spinner} from '../../../common-lib/src/components';
import {SharingModal} from '../../BotPageContainer/components/BotPage/SharingModal/SharingModal';
import {FeedbackModal} from '../../Feedback/Feedback';
import {webApi} from '../../../api/webApi';

type Form = {
	personaName: string;
	personaPronouns: UserPronoun;
	personaDescription: string;
};

export const SHARING_TEXT = "'Check out this Botify AI user!'";

export const ProfileHeader = ({user}: {user: UserPublicInfo | null}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {
		handleLogout: onLogout,
		isMobile,
		user: userMe,
		isLoadingUser,
		handleUpdateUser,
		handleLoginOrPaywall,
	} = useContext(AppContext);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [form, setForm] = useState<Form>({
		personaName: userMe?.personaName || '',
		personaPronouns: userMe?.personaPronouns || null,
		personaDescription: userMe?.personaDescription || '',
	});
	const [isNSFW, setIsNSFW] = useState(userMe?.isNsfwPhotoAllowed || false);
	const {enqueueSnackbar} = useSnackbar();
	const [isSharingOpened, setIsSharingOpened] = useState(false);
	const [showPairTooltip, setShowPairTooltip] = useState(false);

	useEffect(() => {
		setIsNSFW(userMe?.isNsfwPhotoAllowed || false);
	}, [userMe]);

	useEffect(() => {
		if (location.search.includes('open=persona')) {
			setIsDialogOpen(true);
			setShowPairTooltip(true);
			const searchParams = new URLSearchParams(location.search);
			searchParams.delete('open');
			navigate({search: searchParams.toString()});
		}
	}, [location.search]);

	useEffect(() => {
		if (!isLoadingUser) {
			setForm({
				...form,
				personaName: userMe?.personaName || '',
				personaPronouns: userMe?.personaPronouns || null,
				personaDescription: userMe?.personaDescription || '',
			});
		}
	}, [userMe, isLoadingUser]);

	useEffect(() => {
		if (isDialogOpen) {
			setForm({
				...form,
				personaName: userMe?.personaName || '',
				personaPronouns: userMe?.personaPronouns || null,
				personaDescription: userMe?.personaDescription || '',
			});
		}
	}, [isDialogOpen]);

	const handleBack = () => {
		navigate('/');
	};

	const handleLogout = () => {
		onLogout();
		navigate('/');
	};

	const handleEditProfile = () => {
		navigate('/profile-edit');
	};

	const handleEditChatPersona = () => {
		setIsDialogOpen(true);
	};

	const handleUpdateProfile = async (data: Form) => {
		try {
			data.personaName = data.personaName.replace(/[^\p{L}0-9\-\s]/gu, '');
			return usersApi.updateMe(data);
		} catch (e) {
			console.error(e);
			enqueueSnackbar(e?.toString(), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			throw e;
		}
	};

	const handleSave = async (data: Form, file: File | null) => {
		setIsLoading(true);

		const updateAvatarPromise = file
			? usersApi.updateChatPersonaAvatar(file)
			: Promise.resolve();

		return Promise.all([handleUpdateProfile(data), updateAvatarPromise])
			.then(([userResponse, avatarResponse]) => {
				const updatedUser = {...userMe, ...userResponse} as UserInfo;
				if (avatarResponse?.personaAvatar) {
					updatedUser.personaAvatar = avatarResponse.personaAvatar;
				}
				handleUpdateUser(updatedUser);
				enqueueSnackbar('Chat persona updated', {variant: 'success'});
				if (location.search.includes('from=')) {
					const searchParams = new URLSearchParams(location.search);
					navigate(decodeURIComponent(searchParams.get('from') || '/profile'));
				} else {
					navigate('/profile');
				}
			})
			.catch((e) => {
				enqueueSnackbar('Failed to update chat persona', {
					variant: 'error',
				});
				console.error(e);
				throw e;
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleChangeField = (
		field: keyof Form,
		value: string | UserPronoun
	) => {
		setForm((prev) => ({...prev, [field]: value}));
	};

	const handlePersonaChange = (data: {
		name: string;
		pronoun: UserPronoun;
		personality: string;
		file: File | null;
	}) => {
		handleChangeField('personaName', data.name);
		handleChangeField('personaPronouns', data.pronoun);
		handleChangeField('personaDescription', data.personality);

		return handleSave(
			{
				...userMe,
				personaName: data.name,
				personaPronouns: data.pronoun,
				personaDescription: data.personality,
			},
			data.file
		);
	};

	const handleShareClick = () => {
		if (navigator.share) {
			navigator
				.share({
					title: `Botify AI. ${user?.username}`,
					text: SHARING_TEXT,
					url: window.location.href,
				})
				.catch((error) => {
					console.error('Error sharing:', error);
				});
		} else {
			setIsSharingOpened(true);
		}
	};

	const handleNSFWClick = async () => {
		const newNsfw = !isNSFW;
		if (!userMe?.isPaid && newNsfw) {
			handleLoginOrPaywall();
			return;
		}
		setIsNSFW(newNsfw);
		await usersApi
			.updateMe({
				...userMe,
				isNsfwPhotoAllowed: newNsfw,
			})
			.catch((e) => {
				console.error(e);
				enqueueSnackbar('Failed to update NSFW status', {variant: 'error'});
				setIsNSFW(userMe?.isNsfwPhotoAllowed || false);
			});
	};

	const handleReportSubmit = async (reason: string) => {
		const authorId = userMe?.sourceSystemId;
		const userId = user?.sourceSystemId;
		if (!authorId || !userId) {
			return;
		}

		try {
			await webApi.reportUser({userId, reason, authorId});
			enqueueSnackbar('User reported', {variant: 'success'});
		} catch (e) {
			console.log(e);
			enqueueSnackbar('Failed to report user', {variant: 'error'});
		} finally {
			setIsReportDialogOpen(false);
		}
	};

	const isCurrentUser = userMe?.id === user?.id;

	const handleCloseModal = () => {
		setIsDialogOpen(false);
		setShowPairTooltip(false);
	};

	return (
		<HeaderContainer nologo={isMobile}>
			{isLoading && <Spinner withLayout />}
			<div className={cn(styles.container)}>
				<div className={styles.content}>
					<div className={styles.back} onClick={handleBack}>
						<img src={backImg} alt="Back" />
					</div>
					<h2 className={styles.title}>Profile</h2>
					<ActionOptionsBtn noCloseIds={['share-profile', 'nsfw']}>
						{isCurrentUser && (
							<ActionOptionsRow
								icon={editIcon}
								text="Edit Profile"
								onClick={handleEditProfile}
								id="edit-profile"
							/>
						)}
						{isCurrentUser && (
							<ActionOptionsRow
								icon={editIcon}
								text="Edit Chat Persona"
								onClick={handleEditChatPersona}
								id="edit-chat-persona"
							/>
						)}
						<ActionOptionsRow
							icon={shareIcon}
							text="Share Profile"
							onClick={handleShareClick}
							id="share-profile"
						/>
						{!isCurrentUser && (
							<ActionOptionsRow
								icon={reportIcon}
								text="Report user"
								onClick={() => setIsReportDialogOpen(true)}
								isRed
								id="report-user"
							/>
						)}
						{isCurrentUser && (
							<ActionOptionsToggleRow
								icon={eyeIcon}
								text="NSFW Photos"
								onClick={handleNSFWClick}
								id="nsfw"
								isChecked={isNSFW}
								onChange={setIsNSFW}
							/>
						)}
						<ActionOptionsRow
							icon={logoutImg}
							text="Logout"
							onClick={handleLogout}
							id="logout"
						/>
					</ActionOptionsBtn>
				</div>
			</div>
			<SharingModal
				isOpened={isSharingOpened}
				onClose={() => setIsSharingOpened(false)}
				link={window.location.href}
				text={SHARING_TEXT}
				title="Share Profile"
			/>
			<FeedbackModal
				isOpen={isReportDialogOpen}
				onClose={() => setIsReportDialogOpen(false)}
				onSubmit={handleReportSubmit}
			/>
			{isMobile ? (
				<ChatPersonaDialog
					open={isDialogOpen}
					onClose={handleCloseModal}
					onSubmit={handlePersonaChange}
					data={form}
					showPairTooltip={showPairTooltip}
				/>
			) : (
				<ChatPersonaModal
					open={isDialogOpen}
					onClose={handleCloseModal}
					onSubmit={handlePersonaChange}
					data={form}
					showPairTooltip={showPairTooltip}
				/>
			)}
		</HeaderContainer>
	);
};
