import {VISITED_CHAD_ID, FEATURED_CATEGORY_ID} from '../App';
import {CategoriesInfo} from '../api/apiHelper';
import {
	CategoryPreview,
	CategoryData,
	CategoriesAttributes,
} from '../api/types';
import {webApi} from '../api/webApi';

export const combineCategories = (
	data: CategoryPreview[],
	showVisited: boolean
) => {
	const categories = data
		.reduce<CategoriesInfo>((acc, cd) => {
			const catId = cd.id;
			const data: CategoryData = {
				id: catId,
			attributes: {
				...cd,
			},
		};

		acc[catId] = {data, bots: [], hideInSwitch: !data.attributes.showOnWeb};
		return acc;
	}, {});

	if (categories[FEATURED_CATEGORY_ID]) {
		categories[FEATURED_CATEGORY_ID].data.attributes.sort = -101;
	}

	categories['visited'] = {
		data: {
			id: VISITED_CHAD_ID,
			attributes: {
				name: 'Recent chats',
				sort: -100,
				slug: VISITED_CHAD_ID,
				createdAt: '',
				updatedAt: '',
				publishedAt: '',
				locale: '',
				showBanner: false,
				showOnWeb: true,
			},
		},
		hideInSwitch: !showVisited,
		bots: [],
	};

	// categories['new'] = {
	// 	data: {
	// 		id: -1,
	// 		attributes: {
	// 			name: NEW_CATEGORY_NAME,
	// 			sort: -99,
	// 			slug: 'new',
	// 			createdAt: '',
	// 			updatedAt: '',
	// 			publishedAt: '',
	// 			locale: '',
	// 			showBanner: false,
	// 			showOnWeb: true,
	// 		},
	// 	},
	// 	bots: [],
	// };

	categories['week'] = {
		data: {
			id: 'week',
			attributes: {
				name: 'This week',
				sort: -98,
				slug: 'week',
				createdAt: '',
				updatedAt: '',
				publishedAt: '',
				locale: '',
				showBanner: false,
				showOnWeb: false,
			},
		},
		hideInSwitch: true,
		bots: [],
	};

	categories['month'] = {
		data: {
			id: 'month',
			attributes: {
				name: 'This month',
				sort: -97,
				slug: 'month',
				createdAt: '',
				updatedAt: '',
				publishedAt: '',
				locale: '',
				showBanner: false,
				showOnWeb: false,
			},
		},
		hideInSwitch: true,
		bots: [],
	};

	return categories;
};

export const addVisitedCategory = (
	data: CategoriesInfo,
	visited: string[]
): CategoriesInfo => {
	const copy = {...data};
	if (visited.length === 0) {
		return copy;
	}

	//@ts-ignore
	copy['visited'] = {
		data: {
			id: VISITED_CHAD_ID,
			attributes: {
				name: 'Recent chats',
				sort: -100,
				slug: VISITED_CHAD_ID,
				createdAt: '',
				updatedAt: '',
				publishedAt: '',
				locale: '',
				showBanner: false,
				showOnWeb: true,
			},
		},
		hideInSwitch: false,
		bots: [],
	};

	return copy;
};

export const removeVisitedCategory = (data: CategoriesInfo): CategoriesInfo => {
	const copy = {...data};
	delete copy['visited'];
	return copy;
};

export const getFeaturedBots = (isMobile: boolean) => {
	if (isMobile) {
		return Promise.resolve({
			data: [],
		});
	}
	return webApi.getFeaturedBots(0, 10);
};

export const compareTags = (
	newCategories: CategoriesInfo,
	categories: CategoriesInfo
) => {
	let isSame = true;
	Object.keys(newCategories).forEach((key) => {
		const fields: Array<keyof CategoriesAttributes> = [
			'slug',
			'showOnWeb',
			'sort',
		];
		const oldCategory = categories[key];
		const newCategory = newCategories[key];
		if (oldCategory && newCategory) {
			const isSameFields = fields.every(
				(field) =>
					oldCategory.data.attributes[field] ===
					newCategory.data.attributes[field]
			);
			if (!isSameFields) {
				isSame = false;
			}
		} else {
			isSame = false;
		}
	});

	return isSame;
};
