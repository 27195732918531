import {Button, ButtonGroup, Dialog} from '@mui/material';
import styles from './CreateBotMobile.module.css';
import {BotProps} from '../CreateBot';
import {useEffect, useMemo, useState} from 'react';
import {InButton} from '../../../common-lib/src/components/InButton/InButton';
import {Spinner} from '../../../common-lib/src/components/Spinner/Spinner';
import backImg from './images/back.svg';
import cn from 'classnames';
import {TextArea} from '../../../common-lib/src/components/TextArea/TextArea';
import {PublicBot} from '../PublicBot/PublicBot';
import descriptionImg from './images/description.svg';
import appearanceImg from './images/appearance.svg';
import firstMessageImg from './images/firstMessage.svg';
import {SettingsRow} from './SettingsRow';
import voiceImg from './images/voice.svg';
import arrowImg from './images/arrow.svg';
import generateImg from './images/generate.svg';
import {Switcher} from '../../../common-lib/src/components/Switcher/Switcher';
import {TextAreaDialog} from './TextAreaDialog/TextAreaDialog';
import {
	DialogType,
	countAppearancePercantage,
	countDescriptionPercantage,
	countFirstMessagePercantage,
} from './utils';
import {DIALOG_PLACEHOLDERS, DIALOG_TITLES} from './constants';
import {VoiceDialog} from './VoiceDialog/VoiceDialog';
import {CommonTransition} from '../../../common-lib/src/components/CommonTransition/CommonTransition';
import {AiAvatarMobile} from '../AiAvatar/mobile/AiAvatarMobile';
import {AvatarSelectMobile} from '../AvatarSelect/AvatarSelectMobile/AvatarSelectMobile';
import {AvatarDialog} from './AvatarDialog/AvatarDialog';
import {BotData} from '../../../api/types';

type Props = {
	openned: boolean;
	onClose: () => void;
	bot: BotData | null;
	botProps: BotProps;
};

export const CreateBotMobile = ({openned, onClose, bot, botProps}: Props) => {
	const {
		name,
		setName,
		pronoun,
		setPronoun,
		voice,
		setVoice,
		isPublic,
		setIsPublic,
		isAIPopupOpen,
		setIsAIPopupOpen,
		image,
		handleImageChange,
		isAllFilled,
		handleSaveClick,
		isLoading,
		isAvatarLoading,
		avatarData,
		errors,
		hasErrors,
		description,
		setDescription,
		firstMessage,
		setFirstMessage,
		appearance,
		setAppearance,
		handleCheckAppearance,
		handleCheckDescription,
		handleCheckFirstMessage,
		handleCheckName,
		generatePhotos,
		handleChangeGeneratePhotos,
		voices,
	} = botProps;

	const [isLoadingCheck, setIsLoadingCheck] = useState(false);

	const [isFieldDialogOpenned, setIsFieldDialogOpenned] = useState(false);
	const [dialogType, setDialogType] = useState<DialogType>('description');
	const [dialogError, setDialogError] = useState(false);

	const [isVoiceDialogOpenned, setIsVoiceDialogOpenned] = useState(false);
	const [isAvatarDialogOpenned, setIsAvatarDialogOpenned] = useState(false);

	const handleOpenDialog = (type: DialogType) => {
		setDialogType(type);
		setIsFieldDialogOpenned(true);
		setDialogError(false);
	};

	const createSubmitFn =
		(
			checkFn: (text: string, skip: boolean) => Promise<boolean | void>,
			setter: (text: string) => void
		) =>
		async (text: string) => {
			try {
				setDialogError(false);
				setIsLoadingCheck(true);
				const inappropriate = await checkFn(text, true);
				setIsLoadingCheck(false);
				setDialogError(!!inappropriate);

				if (inappropriate) {
					return;
				} else {
					setter(text);
					setIsFieldDialogOpenned(false);
				}
			} catch (e) {
				setIsLoadingCheck(false);
				setDialogError(true);
			}
		};

	const handleSubmitFn = useMemo(() => {
		switch (dialogType) {
			case 'description':
				return createSubmitFn(handleCheckDescription, setDescription);
			case 'appearance':
				return createSubmitFn(handleCheckAppearance, setAppearance);
			case 'firstMessage':
				return createSubmitFn(handleCheckFirstMessage, setFirstMessage);
			default:
				throw new Error('Unknown type');
		}
	}, [dialogType]);
	const dialogValue = useMemo(() => {
		switch (dialogType) {
			case 'description':
				return description;
			case 'appearance':
				return appearance;
			case 'firstMessage':
				return firstMessage;
			default:
				throw new Error('dialogValue Unknow type');
		}
	}, [dialogType, isFieldDialogOpenned]);

	const handleNameBlur = async () => {
		handleCheckName(name);
	};

	const handleCloseAvatarDialog = () => {
		setIsAIPopupOpen(false);
		setIsAvatarDialogOpenned(false);
	};

	useEffect(() => {
		if (openned) {
			document.body.classList.add('scroll-lock');
		} else {
			document.body.classList.remove('scroll-lock');
		}

		return () => {
			document.body.classList.remove('scroll-lock');
		};
	}, [openned]);

	return (
		<Dialog
			fullScreen
			open={openned}
			onClose={onClose}
			TransitionComponent={CommonTransition}
			sx={{
				height: '100dvh',
				backgroundColor: 'black',
				boxSizing: 'border-box',
				width: '100%',
			}}
			className={styles.dialog}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<img
						src={backImg}
						alt="back"
						onClick={onClose}
						className={styles.back}
					/>
					<h2 className={styles.title}>{bot ? 'Edit Bot' : 'Create Bot'}</h2>
				</div>
				<div className={styles.content}>
					<div className={styles.block}>
						<AvatarSelectMobile
							onGenerateClick={() => setIsAIPopupOpen(true)}
							value={image}
							onChange={handleImageChange}
							showError={errors?.photo}
							loading={isAvatarLoading}
							avatarData={avatarData}
							onClick={() => setIsAvatarDialogOpenned(true)}
						/>
					</div>
					<div className={styles.block}>
						<TextArea
							value={name}
							onChange={setName}
							className={styles.name}
							onBlur={handleNameBlur}
							showError={errors?.name}
							placeholder="Name"
							maxLength={30}
						/>
						{errors?.name && (
							<p className={styles.error}>
								There was detected inappropriate content. Please, change the
								text.
							</p>
						)}
					</div>
					<div className={styles.block}>
						<div className={styles.pronounBlock}>
							<ButtonGroup className={cn(styles.pronoun)}>
								<Button
									onClick={() => setPronoun('N/A')}
									className={cn(styles.btn, {
										[styles.btnSelected]: pronoun === 'N/A',
									})}
								>
									N/A
								</Button>
								<Button
									onClick={() => setPronoun('He')}
									className={cn(styles.btn, {
										[styles.btnSelected]: pronoun === 'He',
									})}
								>
									He
								</Button>
								<Button
									onClick={() => setPronoun('She')}
									className={cn(styles.btn, {
										[styles.btnSelected]: pronoun === 'She',
									})}
								>
									She
								</Button>
								<Button
									onClick={() => setPronoun('They')}
									className={cn(styles.btn, {
										[styles.btnSelected]: pronoun === 'They',
									})}
								>
									They
								</Button>
							</ButtonGroup>
						</div>
					</div>
					<div className={styles.block}>
						<PublicBot value={isPublic} onChange={setIsPublic} isSwitch small />
					</div>
					<div className={cn(styles.block, styles.rows)}>
						<SettingsRow
							icon={descriptionImg}
							text="Description"
							onClick={() => handleOpenDialog('description')}
							percentage={countDescriptionPercantage(description.length)}
						/>
						<SettingsRow
							icon={appearanceImg}
							text="Appearance"
							onClick={() => handleOpenDialog('appearance')}
							percentage={countAppearancePercantage(appearance.length)}
						/>
						<SettingsRow
							icon={firstMessageImg}
							text="First message"
							onClick={() => handleOpenDialog('firstMessage')}
							percentage={countFirstMessagePercantage(firstMessage.length)}
						/>
						<div
							className={styles.settingsRow}
							onClick={() => setIsVoiceDialogOpenned(true)}
						>
							<img src={voiceImg} alt="icon" className={styles.settingsIcon} />
							<p className={styles.settingsText}>Voice</p>
							<div className={styles.progressContainer}>
								{voice?.name || 'Select voice'}
							</div>
							<img src={arrowImg} alt="go" className={styles.arrow} />
						</div>
						<div
							className={styles.settingsRow}
							onClick={() => handleChangeGeneratePhotos(!generatePhotos)}
						>
							<img
								src={generateImg}
								alt="icon"
								className={styles.settingsIcon}
							/>
							<p className={styles.settingsText}>Generate photos</p>
							<div className={styles.progressContainer}>
								<Switcher
									checked={generatePhotos}
									onChange={() => handleChangeGeneratePhotos(!generatePhotos)}
								/>
							</div>
						</div>
					</div>
					<InButton
						id="create-character-button"
						isFilled
						className={styles.createButton}
						isDisabled={!isAllFilled || hasErrors}
						onClick={handleSaveClick}
						loading={isLoading}
					>
						{bot ? 'Edit Bot' : 'Create bot'}
					</InButton>
				</div>
				{isLoading && <Spinner />}
			</div>
			{isFieldDialogOpenned && (
				<TextAreaDialog
					openned={isFieldDialogOpenned}
					onClose={() => setIsFieldDialogOpenned(false)}
					isLoading={isLoadingCheck}
					title={DIALOG_TITLES[dialogType]}
					placeholder={DIALOG_PLACEHOLDERS[dialogType]}
					onSubmit={handleSubmitFn}
					value={dialogValue}
					type={dialogType}
					error={dialogError}
				/>
			)}
			{isVoiceDialogOpenned && (
				<VoiceDialog
					openned={isVoiceDialogOpenned}
					onClose={() => setIsVoiceDialogOpenned(false)}
					voice={voice}
					setVoice={setVoice}
					voices={voices}
				/>
			)}
			{isAIPopupOpen && (
				<AiAvatarMobile
					openned={isAIPopupOpen}
					onClose={handleCloseAvatarDialog}
					onChange={handleImageChange}
				/>
			)}
			{isAvatarDialogOpenned && (
				<AvatarDialog
					openned={isAvatarDialogOpenned}
					onClose={() => setIsAvatarDialogOpenned(false)}
					onChange={handleImageChange}
					onGenerateClick={() => setIsAIPopupOpen(true)}
					value={image}
					showError={errors?.photo}
					loading={isAvatarLoading}
					avatarData={avatarData}
				/>
			)}
		</Dialog>
	);
};
