import {InButton} from '../../../common-lib/src/components/InButton/InButton';
import {Spinner} from '../../../common-lib/src/components/Spinner/Spinner';
import {PaywallModalContainer} from '../PWContainer';
import {PWList} from '../PWList';
import {convertPrice} from '../utils';
import styles from './UltraPaywall.module.css';
import cn from 'classnames';
import ultraImg from '../images/ultra.png';
import {useEffect, useState} from 'react';
import {webEventsApi} from '../../../amplitude/webEvents';
import {ProductPrice, ProductsV2} from '../../../api/usersApi/usersApi.types';

type Props = {
	openned: boolean;
	onClose: () => void;
	onSuccess: (
		selectedPrice: ProductPrice,
		selectedPlan: keyof ProductsV2
	) => void;
	loading: boolean;
	loadingProducts: boolean;
	products: ProductsV2 | null;
};

const getPeriodText = (period: ProductPrice['interval']) => {
	if (period === 'year') {
		return 'per year';
	}
	if (period === 'month') {
		return 'per month';
	}
	if (period === 'week') {
		return 'per week';
	}
	return '';
};

export const UltraPaywall = ({
	openned,
	onClose,
	onSuccess,
	loading,
	products,
	loadingProducts,
}: Props) => {
	const ultraProduct = products?.ultra;
	const [selectedPrice, setSelectedPrice] = useState<ProductPrice | null>(null);

	useEffect(() => {
		if (ultraProduct) {
			setSelectedPrice(ultraProduct.prices[0]);
		}
	}, [products]);

	useEffect(() => {
		if (openned && ultraProduct && selectedPrice) {
			webEventsApi.paywallProductSelected({
				product_id: selectedPrice.id,
				product_name: ultraProduct.name,
			});
		}
	}, [openned, selectedPrice]);

	if (!ultraProduct) {
		return null;
	}

	return (
		<PaywallModalContainer
			openned={openned}
			onClose={onClose}
			className={styles.modal}
		>
			<div className={cn('paywall-modal__header', styles.header)}>
				<h2 className="paywall-modal__header-h2">Get</h2>
				<img src={ultraImg} alt="Ultra" className={styles.ultra} />
			</div>
			<PWList
				items={[
					{img: '💖', text: 'Role-playing'},
					{img: '🧠', text: 'Tailor-made AI models'},
					{img: '💫', text: 'Unlimited messages'},
					{img: '🤩', text: 'Exclusive new features'},
				]}
				isUltraSelected
			/>
			{loadingProducts ? (
				<Spinner withLayout={false} />
			) : (
				<>
					<div className={styles.pricesBlock}>
						{ultraProduct?.prices.map((price) => {
							return (
								<div
									className={cn(styles.priceBlock, {
										[styles.selected]: selectedPrice?.id === price.id,
									})}
									onClick={() => setSelectedPrice(price)}
									key={price.id}
								>
									<p className={styles.price}>
										${convertPrice(price.amount)}
										<span className={styles.pricespan}>
											{getPeriodText(price.interval)}
										</span>
									</p>
								</div>
							);
						})}
					</div>
					<p className={styles.subprice}>Auto-renews, cancel at any time</p>
				</>
			)}
			<InButton
				isFilled
				id="continue-paywall-modal"
				onClick={
					loading || !selectedPrice
						? () => {}
						: () => onSuccess(selectedPrice, 'ultra')
				}
				className={cn('paywall-modal__btn', styles.btn, {
					'paywall-modal__btn-loading': loading,
				})}
			>
				{loading ? <>Loading</> : (selectedPrice?.upgrade ? <>Upgrade</> : <>Continue</>)}
			</InButton>
			{selectedPrice?.upgrade && (
				<p className={styles.upgrade}>
					Now you pay only ${convertPrice(selectedPrice.upgrade.amount)} for upgrade
				</p>
			)}
		</PaywallModalContainer>
	);
};
