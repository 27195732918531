import styles from './PeriodSwitcher.module.css';
import cn from 'classnames';
import {Button, ButtonGroup} from '@mui/material';

type Props = {
	onChange: (isUltra: boolean) => void;
	isUltra: boolean;
};

export const PeriodSwitcher = ({onChange, isUltra}: Props) => {
	return (
		<ButtonGroup className={cn(styles.periodSwitcher)}>
			<Button
				onClick={() => onChange(true)}
				className={cn(styles.btn, {[styles.btnSelected]: isUltra})}
			>
				Ultra
			</Button>
			<Button
				onClick={() => onChange(false)}
				className={cn(styles.btn, {[styles.btnSelected]: !isUltra})}
			>
				Premium
			</Button>
		</ButtonGroup>
	);
};
